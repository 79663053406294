<template>
  <div class="encryption-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Encryption</h3>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <div>
        <a-form-model
          ref="encForm"
          :model="encForm"
          :rules="encRules"
        >
          <a-row>
            <a-col :span="9">
              <a-form-model-item ref="body" label="Text to encrypt/decrypt" prop="body">
                <a-textarea v-model="encForm.body" placeholder="Input text" @change="handleInputText" allowClear :rows=6  />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" class="pl-2 pr-2">
              <a-form-model-item ref="algo" label="Algorithm" prop="algo">
                <a-select v-model="encForm.algo" @change="handleAlgoChange">
                  <a-select-option value="rc4">
                    RC4
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <div class="mt-0 text-center d-flex">
                <a-button type="primary" class="mr-1" @click="encrypt" :loading="encLoading" block>Encrypt</a-button>
                <a-button type="primary" class="ml-1" @click="decrypt" :loading="decLoading" block>Decrypt</a-button>
              </div>
              <div class="mt-3 text-center">
                <a-button type="link" @click="reverseFields"><a-icon type="column-width" /> Reverse</a-button>
              </div>
            </a-col>
            <a-col :span="9">
              <a-form-model-item ref="result" label="Result" prop="result">
                <a-textarea v-model="result" placeholder="Result" allowClear :rows=6 />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div @click="refreshToken">---</div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

export default {
  name: 'index',
  components: {
    //
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  data() {
    return {
      accessModule: 'encryption',
      result: '',
      encLoading: false,
      decLoading: false,
      encForm: {
        body: '',
        algo: 'rc4',
      },
      encRules: {
        body: [{ required: true, message: 'Please input text', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  created() {
    //
  },
  methods: {
    refreshToken() {
      this.$store.dispatch('user/REFRESH', { payload: { remember: true } })
    },
    handleInputText(e) {
      // ---
    },
    handleAlgoChange(e) {
      // ---
    },
    reverseFields() {
      const tBody = this.encForm.body
      this.encForm.body = this.result
      this.result = tBody
    },
    encrypt() {
      this.$refs.encForm.validate(valid => {
        if (valid) {
          this.encLoading = true
          const url = '/admin/encryption/encrypt'
          return apiClient.post(url, this.encForm).then((response) => {
            this.$notification.success({
              message: 'Encrypted successfully',
              description: '',
            })
            this.result = response.data.data.encrypted
            this.encLoading = false
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while encrypting body',
              description: error.message,
            })
            this.encLoading = false
          })
        } else {
          console.log('error encForm submit!!')
          return false
        }
      })
    },
    decrypt() {
      this.$refs.encForm.validate(valid => {
        if (valid) {
          this.decLoading = true
          const url = '/admin/encryption/decrypt'
          return apiClient.post(url, this.encForm).then((response) => {
            this.$notification.success({
              message: 'Decrypted successfully',
              description: '',
            })
            this.result = response.data.data.decrypted
            this.decLoading = false
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while decrypting body',
              description: error.message,
            })
            this.decLoading = false
          })
        } else {
          console.log('error encForm submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
